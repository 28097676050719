<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="QRModal"
      ref="QRModal"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div>
        <div
          class="mx-3 mt-3 mb-2 text-center px-3 bg-white header-qr line-clamp-2"
        >
          {{ name }}
        </div>
      </div>
      <div class="position-relative" style="height: 45px">
        <div class="dot dot-left" style="left: 0; z-index: 1"></div>
        <div class="border-behind"></div>
        <div class="dot dot-right" style="right: 0"></div>
      </div>
      <div class="text-center px-3 pb-3">
        <div class="font-weight-bold f-size-18">
          รหัสคูปอง : {{ code ? code : "-" }}
        </div>

        <div>
          <vue-qrcode class="qr-width-70" :value="code" />
        </div>

        <small class="error"
          >คูปองนี้ถ้ากดยืนยันแล้วจะถูกใช้งานทันที <br />
          โปรดแสดงให้พนักงานทราบ
        </small>
        <div class="timer-countdown mt-2">
          <div>QR Code รหัสนี้จะหมดอายุภายใน</div>
          <div>
            {{ countTime }}
          </div>
        </div>
      </div>
      <div
        class="border-top p-2 text-center btn-confirm-popup m-2"
        v-if="allow"
      >
        <div @click="$emit('use')">ยืนยันการใช้งาน</div>
      </div>
      <div class="p-2 text-center btn-outline m-2">
        <div @click="close">ปิดหน้าต่างนี้</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
const moment = require("moment");
var x;
export default {
  props: {
    code: {
      required: true,
      type: String,
      default: ""
    },
    name: {
      required: false,
      type: String
    },
    allow: {
      required: false
    }
  },
  components: {
    VueQrcode
  },
  data() {
    return {
      modalShow: false,
      countDownTime: 10,
      countdown: 10,
      countTime: "00:00"
    };
  },
  mounted() {},
  methods: {
    show() {
      this.modalShow = true;
      this.countTimer();
    },
    close() {
      this.modalShow = false;
      clearInterval(x);
    },
    countTimer() {
      function padZero(value) {
        value = String(value);
        return value.length == 1 ? "0" + value : value;
      }
      var countDownDate = moment().add(10, "minutes").valueOf();
      x = setInterval(() => {
        var now = moment().valueOf();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = padZero(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        var minutes = padZero(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        );
        var seconds = padZero(Math.floor((distance % (1000 * 60)) / 1000));

        if (distance < 0) {
          return clearInterval(x);
          // this.countTime = "EXPIRED";
        }
        this.$nextTick(() => {
          this.countTime = minutes + " : " + seconds;
        });
      }, 900);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
.btn-outline {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.dot {
  display: inline-block;
  top: 6%;
  position: absolute;
  width: 20px;
  height: 45px;
  border: 10px solid #000;
  border-left: 0;
  background-color: #000;
}
.error {
  color: red;
}
.dot-left {
  left: 0;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  right: 0;
}
.border-behind {
  border-top: 4px dashed #dfdfdf;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
}
.line-clamp-2 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
}
.qr-width-70 {
  width: 80%;
}
</style>

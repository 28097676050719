<template>
  <div class="content-detail-privilege">
    <div>
      <div class="privilege-box-detail">
        <b-img
          :src="detail.detail.image_url"
          v-if="detail.detail.image_url"
          @error="handleImageSrc"
          class="w-100"
        />
        <b-img
          :src="require('@/assets/images/default-image.png')"
          v-else
          @error="handleImageSrc"
          class="w-100"
        />

        <div class="p-2">
          <div class="line-clamp-2">
            {{ detail.detail.name }}
          </div>
          <b-row>
            <b-col>
              <div class="valide-until text-gray">
                <div>วันหมดอายุ</div>
                <div>
                  {{
                    detail.detail.end_datetime
                      ? dateFormat(detail.detail.end_datetime)
                      : "-"
                  }}
                </div>
              </div>
            </b-col>
            <b-col
              class="text-right f-sm color-primary f-bold"
              v-if="
                detail.detail.max_quantity != 0 &&
                detail.detail.is_from_stampcard
              "
            >
              <div>สิทธิคงเหลือ</div>
              <div>
                <span>
                  {{ detail.detail.quantity_used || 0 }}/{{
                    detail.detail.max_quantity
                  }}</span
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <hr />
        <div class="description">
          <div class="sub-description">รายละเอียด</div>
          <div
            class="px-3"
            v-if="detail.detail.description"
            @click="handleClick"
            v-html="detail.detail.description"
          ></div>
          <div class="p-3" v-else>-</div>
        </div>
      </div>
    </div>
    <Footer
      @onClick="openQR()"
      :isDisable="detail.detail.status === 0"
      text="กดเพื่อใช้คูปอง"
    />

    <ModalQR
      ref="ModalQR"
      :name="detail.detail.name"
      :code="detail.detail.prefix"
      :allow="detail.detail.allow_customer_redeem"
      @use="showConfirmModal"
    />
    <ModalSelectBranch
      ref="ModalSelectBranch"
      :branch="branchList"
      @confirm="confirmModal"
    />
    <ModalConfirm
      ref="redeemConfirmModal"
      :header="confirmRedeem.header"
      :text="confirmRedeem.text"
      :subText="confirmRedeem.subText"
      :button="confirmRedeem.button"
      @handler="useCoupon"
    />
    <ModalAlert
      ref="modalAlert"
      :text="msg"
      @hide="hideSuccess"
      closeText="ยืนยัน"
    />
    <ModalError ref="modalError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalConfirm from "@/components/alert-modal/ModalConfirm.vue";
import ModalAlert from "@/components/alert-modal/ModalAlert.vue";
import ModalError from "@/components/alert-modal/ModalError.vue";
import ModalLoading from "@/components/other/ModalLoading.vue";
import ModalQR from "@/components/privilege/modal/ModalQR";
import ModalSelectBranch from "@/components/privilege/modal/ModalSelectBranch";
import moment from "moment";
export default {
  components: {
    ModalQR,
    ModalAlert,
    ModalError,
    ModalConfirm,
    ModalSelectBranch,
    ModalLoading
  },
  props: {
    detail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      branchList: [],
      branch_id: 0,
      msg: "คูปองถูกใช้งานเรียบร้อย",
      modalMessage: "แก้ข้อผิดพลาดกรุณาลองใหม่ภายหลัง",
      confirmRedeem: {
        header: "ยืนยันการแลก ?",
        text: "",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-secondary"],
            msg: "ยกเลิก"
          }
        }
      }
    };
  },
  methods: {
    confirmModal(va) {
      this.branch_id = va;
      this.onConfirm();
    },
    handleClick(e) {
      if (e.target.id == "handle-click") {
        this.$liff
          .sendMessages(JSON.parse(e.target.getAttribute("data-message")))
          .then(() => {
            console.log("message sent");
            this.$liff.closeWindow();
          })
          .catch(err => {
            console.log("error", err);
          });
      }
    },
    showConfirmModal() {
      this.confirmRedeem = {
        header: "ยืนยันการแลก",
        text: "<small class='text-danger f-bold'>คูปองนี้ถ้ากดยืนยันแล้วจะถูกใช้งานทันที <br/>โปรดแสดงให้พนักงานทราบ</small>",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-secondary"],
            msg: "ยกเลิก"
          }
        }
      };
      this.$refs.redeemConfirmModal.show();
    },
    hideSuccess() {
      this.$router.push("/privilege");
    },
    async onConfirm(branchId) {
      try {
        this.$refs.modalLoading.show();
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API}/api/v1/Reward/redeem_coupon/${
            branchId || this.branch_id
          }`,
          this.detail.detail
        );
        this.$refs.modalLoading.hide();
        if (!res.result) {
          this.modalMessage = res.message;
          this.$refs.modalError.show();
        } else {
          this.$refs.modalAlert.show();
        }
      } catch (error) {
        console.log(error);
        this.$refs.modalError.show();
      }
    },
    async useCoupon() {
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Reward/redeem_coupon/${this.detail.detail.branch_group_id}`
        )
        .then(async data => {
          var branchList = [];
          for (const { branch } of data.detail) {
            const filter = branch.filter(el => el.is_check);
            branchList.push(...filter);
          }
          this.branchList = branchList;
          if (branchList.length == 1) return this.onConfirm(branchList[0].id);
          this.$refs.ModalSelectBranch.show();
        });
    },
    openQR() {
      this.$refs.ModalQR.show();
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-description {
  padding: 10px;
  // border-bottom: 1px solid gray;
  font-weight: 600;
}
// .btn-black {
//   background-color: #000;
// }
span#handle-click {
  text-decoration: underline;
  cursor: pointer;
}
.status-reward {
  color: lightgreen;
  font-weight: 600;
  font-size: var(--text-md);
  text-align: right;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.receive-point {
  color: #fff;
  text-align: right;
  font-size: var(--text-md);
}
.privilege-box-detail {
  background-color: #fff;
}

::v-deep a {
  color: gray;
}
.text-underline {
  text-decoration: underline;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}

.btn-redeem {
  position: fixed;
  width: 90%;
  bottom: 10px;
  left: 20px;
  right: 10px;
  border: none;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}
.content-detail-privilege {
  margin: 1rem;
  margin-bottom: 18%;
}
.my-20px {
  padding-bottom: 80px;
}
::v-deep p img {
  width: 100% !important;
  height: auto !important;
}
::v-deep img {
  width: 100% !important;
  height: auto !important;
}
::v-deep table {
  width: 100% !important;
  height: auto !important;
}
::v-deep tr {
  height: auto !important;
}
::v-deep td {
  height: auto !important;
}
.text-gray {
  color: #adadad;
}
</style>

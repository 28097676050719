<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="modalBranch"
      ref="modalBranch"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="m-3">
        <div class="modal-confirm-custom text-center title f-xl">
          เลือกสาขาที่ใช้คูปอง
        </div>
        <b-row>
          <b-col>
            <b-form-select
              v-model="selected"
              :options="branch"
              @change="selectBranch"
              value-field="id"
              text-field="name"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--- เลือกสาขาที่ใช้คูปอง --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="border-top p-3 text-center">
        <b-row>
          <b-col>
            <b-button
              @click="$emit('confirm', selected)"
              class="w-100 btn-confirm"
              :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
              >ยืนยัน</b-button
            >
            <b-button
              block
              @click="modalShow = false"
              variant="outline-primary"
              class="mt-2"
              >ยกเลิก</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    branch: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      modalShow: false,
      selected: null,
      body: {
        branch_id: 0,
        redeem_id: 0,
        product_id: 0
      },
      isDisable: true
    };
  },
  methods: {
    show() {
      this.modalShow = true;
      this.selected = null;
    },
    close(val) {
      if (val === 1) {
        this.$emit("success", this.msg);
      } else {
        this.$emit("error", this.msg);
      }
      this.modalShow = false;
    },
    selectBranch(evt) {
      if (evt) this.isDisable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  padding: 15px 0;
}
::v-deep .btn-secondary:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
</style>

<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
      <PrivilegeDetailPanelNewLayout
        v-if="$store.state.theme.line_layout == 'Compact'"
        :detail="privilegeDetail"
      />
      <PrivilegeDetailPanel v-else :detail="privilegeDetail" />
    </div>
  </div>
</template>

<script>
import PrivilegeDetailPanel from "@/components/privilege/detail/PrivilegeDetailPanel";
import PrivilegeDetailPanelNewLayout from "@/components/privilege/detail/PrivilegeDetailPanelNewLayout";
import OtherLoading from "@/components/other/OtherLoading";
import ModalLoading from "@/components/other/ModalLoading";
export default {
  components: {
    PrivilegeDetailPanel,
    PrivilegeDetailPanelNewLayout,
    OtherLoading,
    ModalLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "รายละเอียดสิทธิพิเศษ",
          to: "/privilege"
        }
      ],
      privilegeDetail: [],
      isLoading: true,
      id: this.$route.params.privilegeId,
      user_coupon_id: this.$route.query.user_coupon_id
    };
  },
  async created() {
    await this.getCouponDetail();
  },
  methods: {
    async getCouponDetail() {
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/Privilege/coupon_detail/${this.id}?user_coupon_id=${this.user_coupon_id}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeDetail = data.detail.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
